<template>
  <div
    v-if="marketingData !== undefined"
    class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
  >
    <vx-card>
      <div class="flex items-center justify-between">
        <h2 class="text-xl mb-8 font-medium">{{ title }}</h2>
        <!-- <feather-icon
          class="mb-8"
          icon="HelpCircleIcon"
          svgClasses="w-6 h-6  text-primary"
        ></feather-icon> -->
      </div>
      <p v-if="!marketingData || Object.keys(marketingData).length === 0">
        Não há dados registrados nesse período de tempo 😔
      </p>
      <div class="scrollList">
        <div
          v-for="(ContactChannel, index) in ContactChannelStatistics"
          :key="index"
          :class="{ 'mb-4': index }"
        >
          <div class="flex justify-between">
            <div class="flex flex-row content-center">
              <img
                height="24"
                class="mr-2 align-middle"
                :src="
                  require(`@/assets/images/portrait/icons/${index.toLowerCase()}.png`)
                "
                alt="ContactChannel"
                :title="ContactChannel"
              />
              <h4 class="mb-1 text-lg font-medium mt-1">
                {{ index === "chat" ? "Webchat" : index }}
              </h4>
            </div>
            <div>
              <span class="font-bold"> {{ ContactChannel }}</span>
              <span class="ml-3 text-primary"
                >{{ getPorcentage(ContactChannel) }}%</span
              >
            </div>
          </div>
          <vs-progress
            :color="color"
            :percent="Math.ceil(getPorcentage(ContactChannel))"
          ></vs-progress>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {
    getPorcentage(porcentage) {
      return (this.total > 0 ? (100 / this.total) * porcentage : 0).toFixed(2);
    },
  },
  props: {
    marketingData: Object,
    total: Number,
    color: String,
    title: String,
  },
  data() {
    return {};
  },
  computed: {
    ContactChannelStatistics: function () {
      return this.marketingData
        ? Object.entries(this.marketingData)
            .sort((a, b) => b[1] - a[1])
            .reduce((_sortedObj, [k, v]) => ({ ..._sortedObj, [k]: v }), {})
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollList {
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
}
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  transition: all 0.2s;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  border-radius: 20px;
}
</style>
