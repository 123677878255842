<template>
  <div
    v-if="convPerType !== undefined"
    class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
  >
    <vx-card>
      <div class="flex items-center justify-between">
        <h2 class="text-xl font-medium">{{ cardTitle }}</h2>
        <feather-icon
          class="helpButton"
          @click="teste"
          helpCardText="Teste"
          icon="HelpCircleIcon"
          svgClasses="w-6 h-6  text-primary"
        ></feather-icon>
      </div>
      <ul class="scrollList mt-5 flex flex-col space-y-4">
        <li
          v-for="(conversationPerType, index) in conversationsPerType"
          :key="index"
          class="flex justify-between"
        >
          <p class="text-xl truncate" :title="index">{{ index }}</p>
          <div class="flex space-x-4 items-center justify-center">
            <p class="text-xl">{{ conversationPerType }}</p>
            <vs-chip color="success hidden"
              >{{ getPorcentage(total, conversationPerType) }}%
              <feather-icon icon="ArrowUpIcon" svgClasses="h-4 w-4" />
            </vs-chip>
          </div>
        </li>
      </ul>
      <p v-if="!convPerType || Object.keys(convPerType).length === 0">
        Não há dados registrados nesse período de tempo 😔
      </p>
      <div class="flex flex-col justify-center items-center">
        <!-- <vs-divider />
        <vs-button type="border">Ver Mais</vs-button> -->
      </div>
    </vx-card>

    <vs-popup title="O que é Outbound/Inbound?" :active.sync="isPopupActive">
      <p class="popupContent">
        <span>Outbound:</span> são conversas/contatos gerados ativamente pela
        sua equipe, ou seja, cadastros manuais.
      </p>
      <p class="popupContent">
        <span>Inbound:</span> são conversas/contatos gerados de forma receptiva,
        ou seja, gerados automaticamente.
      </p>
    </vs-popup>
  </div>
</template>
<script>
export default {
  mounted() {},
  methods: {
    getPorcentage(total, porcentage) {
      return total > 0 ? ((100 / total) * porcentage).toFixed(2) : 0;
    },
    teste() {
      this.isPopupActive = !this.isPopupActive;
    },
  },
  props: {
    cardTitle: String,
    convPerType: Object,
    total: Number,
    color: String,
  },
  data() {
    return {
      isPopupActive: false,
    };
  },
  computed: {
    conversationsPerType: function () {
      return this.convPerType
        ? Object.entries(this.convPerType)
            .sort((a, b) => b[1] - a[1])
            .reduce((_sortedObj, [k, v]) => ({ ..._sortedObj, [k]: v }), {})
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollList{
  overflow-y: auto;
  max-height: 200px;
  padding-right: 20px;
}
.popupContent {
  margin-bottom: 10px;

  span {
    font-weight: bold;
  }
}

.helpButton {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.4;
  }
}
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  transition: all .2s;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  border-radius: 20px;
}
</style>
