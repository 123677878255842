<template>
  <vs-card class="p-3">
    <h2 class="text-xl font-medium">{{ title }}</h2>
    <h3 class="font-semibold text-3xl">
      {{ total }}
    </h3>
    <vue-apex-charts
      type="area"
      height="200"
      :series="series"
      :options="chartOptions"
    />
  </vs-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "ChartCSAT",
  props: {
    title: String,
    chartData: Array,
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "CSAT",
          data: Array.isArray(this.chartData)
            ? this.chartData.map((el) =>
                el.value.length === 0 ? 0 : parseInt(el.value)
              )
            : [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
            borderRadius: 10,
          },
        },
        colors: ["#66c871"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        markers: {
          size: 4,
        },
        yaxis: {
          show: false,
        },
        labels: Array.isArray(this.chartData)
          ? this.chartData.map((el) => el.label)
          : [],
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
        },
        reportPerDay: [],
        compKey: 0,
      },
    };
  },
  computed: {
    total: function () {
      if (this.chartData.length == 0) {
        return 0;
      }

      let total = 0;

      this.series[0].data.forEach((item) => {
        total += item;
      });

      const totalLength = this.series[0].data.filter(
        (el) => el !== "0" && el !== 0
      );

      total = totalLength.length > 0 ? total / totalLength.length : 0;

      return Math.round(total);
    },
  },
};
</script>
