<template>
  <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
    <vx-card>
      <div class="flex items-center justify-between">
        <div class="flex flex-col space-y-2">
          <h2 class="text-xl font-medium">{{ title }}</h2>
          <h3 class="font-semibold text-3xl">
            {{ total }}
          </h3>
        </div>
        <!-- <feather-icon
          icon="HelpCircleIcon"
          svgClasses="w-6 h-6 text-primary"
        ></feather-icon> -->
      </div>
      <div>
        <p v-if="!chartData || chartData.length === 0">
          Não há dados registrados nesse período de tempo 😔
        </p>
        <vue-apex-charts
          v-if="chartData && chartData.length > 0"
          type="area"
          height="200"
          :series="series"
          :options="chartOptions"
        ></vue-apex-charts>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  mounted() {},
  props: {
    title: String,
    newConversations: Number,
    chartData: Array,
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "Novas conversas",
          data: Array.isArray(this.chartData)
            ? this.chartData.map((el) => el.value)
            : [],
        },
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          show: false,
        },
        markers: {
          size: 4,
        },
        yaxis: {
          show: false,
        },
        labels: Array.isArray(this.chartData)
          ? this.chartData.map((el) => el.label)
          : [],
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
        },
        reportPerDay: [],
        compKey: 0,
      },
    };
  },
  computed: {
    total: function () {
      return this.chartData &&
        Array.isArray(this.chartData) &&
        this.chartData.length > 0
        ? this.chartData.map((el) => el.value).reduce((a, b) => a + b)
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
