<template>
  <div class="vx-row">
    <CardComponent
      :cardTitle="'Sessões no site'"
      :key="`card1-[${engKeyLocal}]`"
      :cardData="
        String(
          kpiData && kpiData.totalNumChatSessions
            ? kpiData.totalNumChatSessions
            : 0
        )
      "
    />
    <CardComponent
      :cardTitle="'Abertura de chat'"
      :key="`card2-[${engKeyLocal}]`"
      :cardData="
        String(
          kpiData && kpiData.totalNumChatOpen ? kpiData.totalNumChatOpen : 0
        )
      "
    />
    <CardComponent
      :cardTitle="'Engajamento'"
      :key="`card3-[${engKeyLocal}]`"
      :cardData="`${engCalc(
        kpiData && kpiData.totalNumChatOpen ? kpiData.totalNumChatOpen : 0,
        kpiData && kpiData.totalNumChatSessions
          ? kpiData.totalNumChatSessions
          : 0
      )}%`"
    />
    <!-- <CardComponent :cardTitle="'Contatos'" :cardData="marketingData.contacts" /> -->
  </div>
</template>

<script>
import CardComponent from "./CardComponent.vue";

export default {
  mounted() {
    this.engKeyLocal = this.engKey;
  },
  props: {
    kpiData: Object,
    engKey: Number,
  },
  data() {
    return {
      engKeyLocal: 0,
    };
  },
  components: {
    CardComponent,
  },
  methods: {
    engCalc(sessions, openedChats) {
      if (sessions == 0 || openedChats == 0) {
        this.engKeyLocal += 1;
        return 0;
      } else return ((sessions * 100) / openedChats).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped></style>
