<template>
  <vs-card class="p-3">
    <h2 class="text-xl font-medium">{{ title }}</h2>
    <h3 class="font-semibold text-3xl">
      {{ average.days || 0 }}d {{ average.hours || 0 }}h {{ average.minutes || 0 }}m
    </h3>
    <vue-apex-charts
      type="area"
      height="200"
      :series="series"
      :options="chartOptions"
    />
  </vs-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    title: String,
    chartData: Array,
    average: Object
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "Tempo médio de atendimento",
          data: Array.isArray(this.chartData)
            ? this.chartData.map((el) => (el.value.length === 0 ? 0 : el.value))
            : [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
            borderRadius: 10,
          },
        },
        colors: ["#006AB5"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        markers: {
          size: 4,
        },
        yaxis: {
          show: false,
        },
        labels: Array.isArray(this.chartData)
          ? this.chartData.map((el) => el.label)
          : [],
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
        },
        reportPerDay: [],
        compKey: 0,
      },
    };
  }
};
</script>
