<template>
  <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
    <vx-card>
      <div class="flex items-center justify-between">
        <h2 class="text-xl font-medium">{{ cardTitle }}</h2>
        <!-- <feather-icon
          icon="HelpCircleIcon"
          svgClasses="w-6 h-6  text-primary"
        /> -->
      </div>
      <div class="mt-2">
        <h3 class="text-3xl font-semibold">
          {{ data }}
        </h3>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: String,
    cardData: String,
  },
  data() {
    return {
      data: this.cardData,
    };
  },
};
</script>
