var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row"},[_c('CardComponent',{key:`card1-[${_vm.engKeyLocal}]`,attrs:{"cardTitle":'Sessões no site',"cardData":String(
        _vm.kpiData && _vm.kpiData.totalNumChatSessions
          ? _vm.kpiData.totalNumChatSessions
          : 0
      )}}),_c('CardComponent',{key:`card2-[${_vm.engKeyLocal}]`,attrs:{"cardTitle":'Abertura de chat',"cardData":String(
        _vm.kpiData && _vm.kpiData.totalNumChatOpen ? _vm.kpiData.totalNumChatOpen : 0
      )}}),_c('CardComponent',{key:`card3-[${_vm.engKeyLocal}]`,attrs:{"cardTitle":'Engajamento',"cardData":`${_vm.engCalc(
      _vm.kpiData && _vm.kpiData.totalNumChatOpen ? _vm.kpiData.totalNumChatOpen : 0,
      _vm.kpiData && _vm.kpiData.totalNumChatSessions
        ? _vm.kpiData.totalNumChatSessions
        : 0
    )}%`}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }