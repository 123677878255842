<template>
  <div
    v-if="convPerIntention !== undefined"
    class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
  >
    <vx-card>
      <div class="flex items-center justify-between mb-6">
        <div class="flex flex-col space-y-2">
          <h2 class="text-xl font-medium">{{ cardTitle }}</h2>
        </div>
        <!-- <feather-icon
          icon="HelpCircleIcon"
          svgClasses="w-6 h-6 text-primary"
        ></feather-icon> -->
      </div>
      <div class="mt-4">
        <ul
          class="flex flex-col space-y-4 mb-4 max-h-64 overflow-y-auto scrollbar pr-2"
        >
          <li
            v-for="(
              ConversationsByIntentions, index
            ) in ConversationsByIntentionsStatistics"
            :key="ConversationsByIntentions.id"
          >
            <div class="flex justify-between">
              <h4 class="text-lg font-medium truncate" :title="index">{{ index }}</h4>
              <div>
                <span class="font-bold"> {{ ConversationsByIntentions }}</span>
                <span class="ml-3 text-primary"
                  >{{
                    getPorcentage(total, ConversationsByIntentions).toFixed(2)
                  }}%</span
                >
              </div>
            </div>
            <vs-progress
              :color="color"
              :percent="
                Math.ceil(getPorcentage(total, ConversationsByIntentions))
              "
            ></vs-progress>
          </li>
        </ul>
        <p
          v-if="!convPerIntention || Object.keys(convPerIntention).length === 0"
        >
          Não há dados registrados nesse período de tempo 😔
        </p>
      </div>
    </vx-card>
  </div>
</template>

<script>

export default {
  mounted() {},
  components: {},
  methods: {
    getPorcentage(total, porcentage) {
      return total > 0 ? (100 / total) * porcentage : 0;
    },
  },
  props: {
    cardTitle: String,
    convPerIntention: Object,
    total: Number,
    color: String,
  },
  data() {
    return {};
  },
  computed: {
    ConversationsByIntentionsStatistics: function () {
      return this.convPerIntention
        ? Object.entries(this.convPerIntention)
            .sort((a, b) => b[1] - a[1])
            .reduce((_sortedObj, [k, v]) => ({ ..._sortedObj, [k]: v }), {})
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.max-h-64 {
  max-height: 16rem;
}
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  transition: all .2s;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  border-radius: 20px;
}

</style>
